import { CouponsErrorType } from '../../../../types/types';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type RemoveCoupon = () => void;

export function createRemoveCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): RemoveCoupon {
  return async () => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { errors } = state;

    const errorsWithoutCouponError = errors.filter(
      (error) => !Object.keys(CouponsErrorType).includes(error),
    );

    setState({
      couponDiscount: 0,
      errors: errorsWithoutCouponError,
    });
  };
}
