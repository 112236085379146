import { CouponsErrorType } from '../../../../types/types';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type SetCoupon = (couponCode: string) => void;

export function createSetCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): SetCoupon {
  return async (couponCode: string) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi } = actionFactoryParams.context;
    const { numberOfParticipants, slotAvailability, service, errors } = state;
    const slot = slotAvailability?.slot!;
    const rate = service.rate;
    const errorsWithoutCouponError = errors.filter(
      (error) => !Object.keys(CouponsErrorType).includes(error),
    );
    if (couponCode) {
      try {
        const getPaymentsDetails = await formApi.getPaymentsDetails(
          couponCode,
          slot,
          numberOfParticipants,
          rate,
        );
        const couponDiscount =
          +getPaymentsDetails?.couponDetails?.couponDiscount! || 0;
        setState({
          couponDiscount,
          errors: errorsWithoutCouponError,
        });
      } catch (e) {
        const errorResponse = JSON.stringify(e);
        const error = Object.keys(CouponsErrorType).find((err) =>
          errorResponse.includes(err),
        ) as CouponsErrorType;
        if (error) {
          setState({
            couponDiscount: 0,
            errors: [...state.errors, error],
          });
        }
      }
    } else {
      setState({
        couponDiscount: 0,
        errors: errorsWithoutCouponError,
      });
    }
  };
}
