import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import { mapSubmissionToPartialBookRequest } from '../../../../utils/mappers/form-submission.mapper';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';

export type OnSubmit = (submission: SubmissionResponse) => void;

export function createOnSubmitAction({
  context: { wixSdkAdapter, formApi },
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): OnSubmit {
  return async (submissionResponse) => {
    const [state] = getControllerState();
    const {
      service,
      businessInfo,
      slotAvailability,
      selectedPaymentOptionId,
      pricingPlanDetails,
    } = state;

    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      return purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
    }

    if (submissionResponse.state.valid) {
      if (wixSdkAdapter.isPreviewMode()) {
        return wixSdkAdapter.navigateToBookingsWithSuffix();
      }
      const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
        submissionResponse.submission,
        service,
        businessInfo,
      );
      const selectedPlan = pricingPlanDetails?.plans?.find(
        (plan) => plan?.paidPlan?.planId === selectedPaymentOptionId,
      );

      const bookingResponse = await formApi.book({
        service,
        formInfo,
        slotAvailability,
        selectedPlan,
        sendSmsReminder,
      });
      return wixSdkAdapter.navigateToBookingsCheckout(
        bookingResponse.booking!,
        widgetDefaults.pageName,
      );
    }
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { serviceId, slotAvailabilityParams, referral, timezone } =
    getQueryParams(wixSdkAdapter);

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone,
      service: serviceId,
      slot: slotAvailabilityParams,
    },
  });
}

export function getQueryParams(wixSdkAdapter: WixOOISDKAdapter): {
  [key: string]: string;
} {
  const serviceId = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.SERVICE_ID,
  );

  const slotAvailabilityParams = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.AVAILABILITY_SLOT,
  );

  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );

  const timezone = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.TIMEZONE,
  );

  return { serviceId, slotAvailabilityParams, referral, timezone };
}
