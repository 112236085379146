import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type SetNumberOfParticipants = (numberOfParticipants: number) => void;

export function createSetNumberOfParticipantsAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): SetNumberOfParticipants {
  return async (numberOfParticipants: number) => {
    const [, setState] = getControllerState();

    setState({ numberOfParticipants });
  };
}
