import {
  FormField,
  ValueType,
} from '@wix/ambassador-services-catalog-server/types';
import { CatalogData } from '../../api/types';
import {
  DisplayPropertiesFieldOption,
  Operation,
  V2Rule,
} from '@wix/ambassador-wix-form-builder-web/types';
import {
  SlotAvailability,
  LocationType,
} from '@wix/ambassador-availability-calendar/types';
import {
  createAddressField,
  createFormField,
  FieldLayout,
  getFieldType,
} from './service-form-field.mapper';
import { FormFieldViewInfoFieldType, FormView } from '@wix/forms-ui/types';
import { BookingRequestKeyMappings } from './form-submission.mapper';
import { TFunction } from '../../types/types';

export enum ReservedFieldIds {
  SMS_REMINDER = 'sms-reminder-id',
}

export const ValidationPatterns = {
  EMAIL:
    "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])",
  PHONE: '[\\d\\s\\+\\-\\(\\)\\.]',
};

export const createFormView = ({
  catalogData,
  availability,
  t,
}: {
  catalogData: CatalogData;
  t: TFunction;
  availability?: SlotAvailability;
}): FormView => {
  const form = catalogData.service.form!;
  const { customFields = [] } = form;

  const { isSMSReminderEnabled } = catalogData.businessInfo;
  const phoneFieldType = isSMSReminderEnabled
    ? FormFieldViewInfoFieldType.PHONE_COUNTRY_CODE
    : FormFieldViewInfoFieldType.PHONE;

  const maxParticipantsPerBooking =
    catalogData?.service?.service?.policy?.maxParticipantsPerBooking ?? 0;

  const availableSpots = availability?.openSpots ?? 0;
  const maxNumberOfParticipants = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
  });
  const numberOfParticipantsDefaultValue = '1';

  const fields = [
    createFormField({
      field: form.name!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.FIRST_NAME,
      },
      fieldType: FormFieldViewInfoFieldType.TEXT,
      layout: FieldLayout.SHORT,
    }),
    createFormField({
      field: form.email!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.EMAIL,
      },
      fieldType: FormFieldViewInfoFieldType.EMAIL,
      layout: FieldLayout.SHORT,
      validations: {
        pattern: ValidationPatterns.EMAIL,
      },
    }),
    createFormField({
      field: form.phone!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.PHONE,
      },
      fieldType: phoneFieldType,
      layout: isSMSReminderEnabled ? FieldLayout.LONG : FieldLayout.SHORT,
      validations: isSMSReminderEnabled
        ? {
            pattern: ValidationPatterns.PHONE,
          }
        : {},
    }),
    ...(isSMSReminderEnabled
      ? [
          createFormField({
            field: {
              label: t('app.booking-form.fields.sms-reminder.label'),
              fieldId: ReservedFieldIds.SMS_REMINDER,
            },
            bookingsKeyMapping: {
              key: BookingRequestKeyMappings.SMS_REMINDER,
            },
            fieldType: FormFieldViewInfoFieldType.CHECKBOX,
          }),
        ]
      : []),
    ...(maxNumberOfParticipants > 1
      ? [
          createFormField({
            field: form.numberOfParticipants!,
            fieldType: FormFieldViewInfoFieldType.DROP_DOWN,
            bookingsKeyMapping: {
              key: BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
            },
            displayProperties: {
              options: getNumberOfParticipantsOptions(maxNumberOfParticipants),
              defaultValue: numberOfParticipantsDefaultValue,
            },
          }),
        ]
      : []),
    ...(shouldShowAddressField(availability) && form.address
      ? [createAddressField(form.address!)]
      : []),
    ...customFields.map((field: FormField) => {
      const fieldType = getFieldType(field.valueType!);
      const layout =
        fieldType === FormFieldViewInfoFieldType.TEXT
          ? FieldLayout.SHORT
          : FieldLayout.LONG;
      return createFormField({
        field,
        fieldType,
        layout,
        validations: getValidationValues(field),
      });
    }),
  ];

  const rule: V2Rule[] = [
    {
      enabled: isSMSReminderEnabled,
      condition: {
        eq: { fieldKey: ReservedFieldIds.SMS_REMINDER, value: true },
      },
      actions: [
        { fieldKeys: [form.phone!.fieldId!], operation: Operation.REQUIRED },
      ],
    },
  ];

  return {
    formId: form.id,
    fields,
    formViewInfo: {
      rule,
    },
  };
};

const shouldShowAddressField = (availability?: SlotAvailability) => {
  return availability?.slot?.location?.locationType === LocationType.CUSTOM;
};

const getNumberOfParticipantsOptions = (
  maxOption: number,
): DisplayPropertiesFieldOption[] => {
  return Array.from({ length: maxOption }, (_, key) => ({
    value: `${key + 1}`,
    label: `${key + 1}`,
  }));
};

const getMaxNumberOfParticipants = ({
  availableSpots,
  maxParticipantsPerBooking,
}: {
  maxParticipantsPerBooking: number;
  availableSpots: number;
}) => {
  return Math.min(maxParticipantsPerBooking, availableSpots);
};

const getValidationValues = (field?: FormField) => {
  switch (field?.valueType) {
    case ValueType.LONG_TEXT:
      return { maxLength: 255 };
    case ValueType.SHORT_TEXT:
      return { maxLength: 30 };
  }
  return {};
};
