import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';

export type OnLogin = (user?: IUser) => Promise<void>;

export function createOnLoginAction({
  getControllerState,
  context: { formApi },
}: ActionFactoryParams<FormState, FormContext>): OnLogin {
  return async (user) => {
    const [, setState] = getControllerState();
    if (user) {
      try {
        const memberDetails = await formApi.getMemberDetails(user.id);
        setState({ memberDetails });
      } catch (e) {}
    }
  };
}
