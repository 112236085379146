import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  OfferedAsType,
  ServiceLocationType,
  ServiceType,
  TimezoneType,
} from '@wix/bookings-uou-types';
import { PaymentType, Service } from '../mappers/service.mapper';
import {
  FormFieldViewInfoFieldType,
  FormViewField,
  ValidationProperties,
} from '@wix/forms-ui/types';
import { BookingRequestKeyMappings } from '../mappers/form-submission.mapper';
import {
  FormState,
  getDefaultPaymentOptionId,
} from '../state/initialStateFactory';
import { BusinessInfo, TFunction } from '../../types/types';
import { FieldLayout } from '../mappers/service-form-field.mapper';
import { ValidationPatterns } from '../mappers/service-form.mapper';

export function createDummyState(
  flowApi: ControllerFlowAPI,
  businessInfo: BusinessInfo,
  isPricingPlanInstalled: boolean,
): FormState {
  const pricingPlanDetails = { plans: [] };
  const service = createDummyService(flowApi, businessInfo);
  return {
    service,
    businessInfo,
    activeFeatures: createDummyActiveFeatures(),
    slotAvailability: createDummySlotAvailability(),
    pricingPlanDetails,
    couponDiscount: 0,
    numberOfParticipants: 1,
    selectedPaymentOptionId: getDefaultPaymentOptionId(
      flowApi.settings,
      service.payment,
      pricingPlanDetails,
    ),
    memberDetails: {},
    errors: [],
    hasCoupon: false,
    isPricingPlanInstalled,
    showCouponInput: false,
    editorContext: {
      isDummy: true,
    },
  };
}

function createDummyService(
  flowApi: ControllerFlowAPI,
  businessInfo: BusinessInfo,
): Service {
  const { t } = flowApi.translations;
  return {
    name: t('app.dummy-data.service'),
    staffMembers: [
      {
        id: 'dummy-staff-id',
        name: t('app.dummy-data.staff'),
      },
    ],
    // rate: Rate;
    location: {
      id: 'dummy-location-id',
      locationType: ServiceLocationType.OWNER_CUSTOM,
      address: t('app.dummy-data.location'),
    },
    isPendingApprovalFlow: false,
    isWaitingListFlow: false,
    videoConferenceProviderId: 'some-video-provider',
    paymentTypes: [PaymentType.ONLINE, PaymentType.PRICING_PLAN],
    type: ServiceType.INDIVIDUAL,
    scheduleId: 'dummy-schedule-id',
    formSchema: {
      fields: [
        createDummyFormField({
          id: 'dummy-first-name-field',
          label: t('app.dummy-data.field.first-name'),
          type: FormFieldViewInfoFieldType.TEXT,
          layout: FieldLayout.SHORT,
          validationProperties: {
            required: true,
          },
        }),
        createDummyFormField({
          id: 'dummy-last-name-field',
          label: t('app.dummy-data.field.last-name'),
          type: FormFieldViewInfoFieldType.TEXT,
          layout: FieldLayout.SHORT,
          validationProperties: {
            required: true,
          },
        }),
        createDummyFormField({
          id: 'dummy-email-field',
          label: t('app.dummy-data.field.email'),
          type: FormFieldViewInfoFieldType.EMAIL,
          layout: FieldLayout.SHORT,
          validationProperties: {
            required: true,
            pattern: ValidationPatterns.EMAIL,
          },
        }),
        createDummyFormField({
          id: 'dummy-phone-field',
          label: t('app.dummy-data.field.phone'),
          type: FormFieldViewInfoFieldType.PHONE,
          layout: FieldLayout.SHORT,
        }),
        createDummyFormField({
          id: 'dummy-message-field',
          label: t('app.dummy-data.field.add-message'),
          type: FormFieldViewInfoFieldType.PARAGRAPH,
        }),
      ],
    },
    formHeader: {
      title: t('app.dummy-data.header.title'),
      // description: t('app.dummy-data.header.description'),
      isDescriptionHidden: true,
    },
    payment: {
      offeredAs: [OfferedAsType.ONE_TIME, OfferedAsType.PRICING_PLAN],
      paymentDetails: {
        currency: businessInfo.currency,
        priceText: t('app.dummy-data.price'),
        isFree: false,
        paymentType: PaymentType.ONLINE,
      },
      pricingPlanInfo: {
        pricingPlans: [
          {
            id: 'dummy-pricing-plan',
            name: 'Pricing Plan',
          },
        ],
      },
    },
    actionLabels: {
      offlinePaymentLabel: t('app.dummy-data.button'),
    },
  } as unknown as Service;
}

export function createDummySubmission(t: TFunction) {
  return {
    'dummy-first-name-field': t('app.dummy-data.field.first-name.place-holder'),
    'dummy-last-name-field': t('app.dummy-data.field.last-name.place-holder'),
    'dummy-email-field': t('app.dummy-data.field.email.place-holder'),
  };
}
export function createDummyEmptySubmission() {
  return {
    'dummy-first-name-field': '',
    'dummy-last-name-field': '',
    'dummy-email-field': '',
  };
}

export function createDummyBusinessInfo(flowApi: ControllerFlowAPI) {
  const { config } = flowApi.translations;
  return {
    language: config.language,
    currency: 'USD',
    timeZone: 'Asia/Jerusalem',
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: 'en-US',
  };
}

function createDummyActiveFeatures(): GetActiveFeaturesResponse {
  return {
    applicableForCourse: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForServiceList: true,
    applicableForSmsReminders: true,
    bookingsStaffLimit: 100,
  };
}

function createDummySlotAvailability(): SlotAvailability {
  return {
    slot: {},
  } as SlotAvailability;
}

function createDummyFormField({
  id,
  label,
  bookingsKey,
  type,
  layout,
  validationProperties,
}: {
  id: string;
  label: string;
  type: FormFieldViewInfoFieldType;
  layout?: FieldLayout;
  bookingsKey?: BookingRequestKeyMappings;
  validationProperties?: ValidationProperties;
}): FormViewField {
  return {
    externalId: id,
    renderInfo: {
      type,
      displayProperties: {
        label,
      },
      metadata: {
        ...(bookingsKey ? { bookingsKey } : {}),
        ...(layout
          ? {
              layout: { appearance: layout },
            }
          : {}),
      },
      ...(validationProperties
        ? {
            validationProperties,
          }
        : {}),
    },
  };
}
