import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type PromptLogin = () => void;

export function createPromptLoginAction({
  context: { wixSdkAdapter },
}: ActionFactoryParams<FormState, FormContext>): PromptLogin {
  return () => {
    return wixSdkAdapter.promptUserLogin();
  };
}
